import { useQuery, useInfiniteQuery } from "vue-query";
import { reactive, ref } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
// import store from "@/store";
import { httpReq } from "@/htttpReq";


const fetchData = async ({ pageParam = 1, queryKey }, queryParams) => {
  const { search, paginate, ...restOfTheQuery } = queryParams;
  let paginateQuery = paginate ? { paginate } : { paginate: 10 };
  // filter[search]=${queryParams}

  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists/non-attached-customers`, {
    ...restOfTheQuery,
    ...(search? {'filter[search]': search}: {}),
    ...paginateQuery,
    ...{ page: pageParam }
  });
  return {
    data: response.data.data,
    pagination: response.data.pagination,
  }
};

export default function useGetCustomers(searchTerm) {

  const queryKey = reactive(["price-lists-customers", { searchTerm }]);

  return useInfiniteQuery(
    queryKey,
    (params) => fetchData(params, {search: searchTerm.value}),
    {
      getNextPageParam: (lastPage, pages) => {
        const { pagination } = lastPage;
        return !pagination.has_more_pages
          ? undefined
          : Number(pagination.current_page) + 1;
      },
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: (error) => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
