<script setup>
import { ref, computed } from '@vue/reactivity'
import { watch } from "@vue/runtime-core"
import _ from 'lodash'
import CustomerSelection from './CustomerSelection.vue'
import NoResults from '../../../../components/NoResults.vue'
import useAttachCustomers from '../../../../hooks/price-lists/useAttachCustomers'
import useDettachCustomer from '../../../../hooks/price-lists/useDettachCustomer'
import CustomerCard from './CustomerCard.vue'
import DeleteConfirmationModal from '@/components/commons/DeleteConfirmationModal.vue'
const props = defineProps({
  pricelistCustomers: {
    type: Array,
    default: []
  },
  pricelistId: {
    type: [String,Number],
    default: undefined,
  }
})

const emits = defineEmits(['pricelistCustomersUpdated'])

const { attachCustomers, attachCustomersStatus } = useAttachCustomers()
const { dettachCustomer, dettachCustomerStatus } = useDettachCustomer()

const showCustomerSelectionModal = ref(false)
const showDeleteConfirmationModal = ref(false)
const updatedCustomers = ref(_.cloneDeep(props.pricelistCustomers))
const searchText = ref('')
const customerToBeDeleted = ref({})

const filteredCustomers = computed(() => {
  if(searchText.value === '') return updatedCustomers.value

  updatedCustomers.value
})

const handleCustomerSelection = async (customerList) => {
  showCustomerSelectionModal.value = false

  const ids = updatedCustomers.value.map(i => i.id)
  const customersNoDup = customerList.filter(i => !ids.includes(i.id) )
  const customerNoDupIds = customersNoDup.map(i => i.id)
  if(props.pricelistId) {
    await attachCustomers({
      pricelistId: props.pricelistId,
      customerIds: customerNoDupIds,
    })
  }

  updatedCustomers.value.push(...customersNoDup)

}

watch(updatedCustomers, (val, oldVal) => {
  emits('pricelistCustomersUpdated', val)
}, {deep: true, immediate: true})

const openCustomerRemoveConfirmation = async (customer) => {
  showDeleteConfirmationModal.value = true
  customerToBeDeleted.value = customer
}

const removeCustomer = async () => {
  showDeleteConfirmationModal.value = true
  if(props.pricelistId) {
    await dettachCustomer({
      pricelistId: props.pricelistId,
      customerId:  customerToBeDeleted.value.id,
    })
  }
  updatedCustomers.value =  updatedCustomers.value.filter(x => x.id !==  customerToBeDeleted.value.id)
}

</script>
<template>
  <div class="tw-text-black-gray" :class="{'tw-animate-pulse tw-pointer-events-none tw-opacity-70 tw-duration-300': attachCustomersStatus.isLoading || dettachCustomerStatus.isLoading}">
    <div class="tw-flex tw-bg-secondary tw-py-2 tw-px-3 tw-justify-between tw-flex-wrap tw-gap-4">
        <!-- Filters -->
        <div class="tw-flex tw-flex-1- tw-gap-4 tw-items-center tw-flex-wrap">
            <label class="tw-flex tw-items-center ">
                <input
                    type="text"
                    class="form__input tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-2 tw-py-0.5"
                    name="query"
                    placeholder="Search"
                    v-model="searchText"
                  />
              </label>
        </div>
        <!-- Actions -->
        <div class="tw-flex tw-items-center tw-gap-4">
            <button type="button" @click="showCustomerSelectionModal = true" class="tw-whitespace-nowrap tw-btn-secondary-success tw-bg-transparent hover:tw-bg-primary hover:tw-text-white">
                Add Customer
            </button>
        </div>
    </div>

    <!-- Body -->
    <NoResults class="tw-h-52" v-if="updatedCustomers.length === 0"></NoResults>
    <div v-else class="tw-shadow">
      <div class="tw-grid tw-grid-cols-1
          sm:tw-grid-cols-2
          md:tw-grid-cols-3
          tw-gap-4">
        <div v-for="customer in filteredCustomers" :key="customer.id" class="">
          <CustomerCard :customer="customer" @removeCustomer="openCustomerRemoveConfirmation(customer)" ></CustomerCard>
        </div>
      </div>

    </div>

    <CustomerSelection
      v-if="showCustomerSelectionModal"
      :selectedCustomers="[]"
      @itemSelectionUpdated="handleCustomerSelection"
      @modalClosed="showCustomerSelectionModal = false"
    />

    <DeleteConfirmationModal v-model:show="showDeleteConfirmationModal" @continue="removeCustomer()"/>
  </div>
</template>
