<script setup>
import _ from "lodash";
import Modal from "../../../../components/modals/Modal.vue";
import { ref, computed, reactive } from "@vue/reactivity";
import InlinePreloader from "../../../../components/parts/inlinePreloader.vue";
import useGetCustomers from "../../../../hooks/price-lists/useGetCustomers";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

const props = defineProps({
  multiple: {
    type: Boolean,
    default: true,
  },
  selectedCustomers: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits([
  "itemSelectionUpdated",
  "selectionCleared",
  "modalClosed",
]);

const searchTerm = ref("");

const {
  data,
  pagination,
  isLoading,
  isFetching,
  isRefetching,
  fetchNextPage,
  hasNextPage,
} = useGetCustomers(searchTerm);

const totalCustomers = ref(0)

const items = computed(() => {
  const resultArray = [];
  for (let page in (data.value || []).pages) {
    totalCustomers.value = data.value.pages[page]?.pagination?.total
    resultArray.push(...data.value.pages[page].data);
  }
  return resultArray;
});


const startInfiniteLoading = async ($state) => {
    await fetchNextPage.value()
    $state.loaded();
    if(!hasNextPage) {
      $state.complete();
    }
};

const selectedLocal = ref(props.selectedCustomers);

const updateSearch = _.debounce((v) => {
  searchTerm.value = v;
}, 200);

const handleSearchTerm = (e) => {
  updateSearch(e.target.value);
};

const apply = () => {
  emits("itemSelectionUpdated", selectedLocal.value);
};

const clearFilter = () => {
  selectedLocal.value = [];
  emits("selectionCleared");
};
</script>

<template>
  <Modal :show="true">
    <div class="tw-card-modal tw-modal tw-mx-4 tw-my-8 tw-max-w-5xl tw-z-10">
      <div
        class="tw-card-head-modal tw-flex tw-justify-between tw-items-center"
      >

        <h4 class="tw-font-normal">
          SELECT CUSTOMERS
          <span v-if="items && items.length">({{ totalCustomers }})</span>
        </h4>
        <button
          type="button"
          class="btn btn_transparent tw-m-0 tw-border-none"
          @click="emits('modalClosed')"
        >
          <svg-icon name="solid/times"></svg-icon>
        </button>
      </div>
      <div id="modalBody" class="tw-card-body-modal with-scroll with-footer">

        <label class="form__label tw-m-0 tw-py-1.5">
          <input
            type="search"
            class="form__input"
            name="query"
            placeholder="Search"
            :value="searchTerm"
            @input="handleSearchTerm"
          />
        </label>
        <InlinePreloader
          v-if="isLoading"
          message="Loading customers..."
        ></InlinePreloader>
        <template v-else>
          <div
            class="tw-transition-all"
            :class="[
              isFetching
                ? 'tw-animate-pulse tw-pointer-events-none tw-opacity-60-'
                : '',
            ]"
          >
            <div class="row">
              <div class="col-12">
                <div
                  v-for="(item, index) in items"
                  :key="index"
                  class="row entry"
                  :class="{ pointer: !multiple }"
                >
                  <div class="col-12 col-md-8">
                    <label v-if="multiple" class="form__label form__label_row">
                      <input
                        type="checkbox"
                        class="form__radio"
                        hidden
                        :value="item"
                        v-model="selectedLocal"
                      />
                      <span
                        :class="
                          multiple ? 'form__checkbox_icon' : 'form__radio_icon'
                        "
                      ></span>
                      <span class="form__labelTitle text_bold item_name">{{
                        item.display_name ?? item.name
                      }}</span>
                    </label>
                    <span
                      v-else
                      class="form__labelTitle text_bold item_name"
                      >{{ item.display_name ?? item.name }}</span
                    >
                    <p>{{ item.email }}</p>
                  </div>
                  <div class="col-12 col-md-4 text_right">
                    {{ item.email }}
                  </div>

                  <div class="col-12 col-md-6">
                    <p class="text_small">
                      {{ item.address }} {{ item.suburb }}
                      {{ item.postcode }}
                    </p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="text_small">Phone: {{ item.phone }}</p>
                  </div>
                  <!-- <div class="col-12 col-md-3 text_right">
                                  <p class="text_small">Signed up on: </p>
                              </div> -->
                </div>
                <div class="loader tw-flex tw-mt-4 tw-items-center tw-justify-center">
                    <InfiniteLoading
                      :firstLoad="false"
                      :data="[]"
                      @infinite="startInfiniteLoading"
                      target="#modalBody"
                    />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="
          tw-card-footer tw-flex tw-justify-center tw-items-center tw-gap-4
        "
      >
        <button class="btn btn_default m-0" @click="apply">

            APPLY
        </button>
        <!-- <button class="btn btn_default m-0" @click="clearFilter">
          CLEAR FILTER
        </button> -->
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
@import "../../../../assets/scss/utils/vars";

.entry {
  background-color: #eeeeee;
  margin: 3px auto;
  padding: 5px;

  &:hover {
    background-color: rgba(85, 180, 148, 0.4);
  }

  .item_name {
    font-size: 1.1em;
    color: $navy;
  }
}
.form__input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.loader ::v-deep .container{
  padding: 0;
}
</style>
